<template>
    <div class="week-calendar">
        <div class="calendar-week">
            <div
                :class="displayDays.length > 0 && 'padding-border'"
                class="hours"
            >
                <div
                    v-for="hour in hours"
                    :key="`hou_${hour.name}`"
                    class="hour"
                >
                    <div v-if="hour.name">
                        <span class="only-mobile">
                            {{ formatHour(hour.name, true) }}
                        </span>
                        <span class="no-mobile">
                            {{ formatHour(hour.name) }}
                        </span>
                    </div>
                </div>
            </div>
            <div :class="readonly && 'readonly'" class="calendar-cells">
                <div class="days">
                    <div
                        v-for="day in days"
                        :key="`day_${day.value}`"
                        class="day"
                    >
                        <span
                            class="only-mobile"
                            :class="
                                displayDays.findIndex((e) => e === day.value) >=
                                    0 && 'selected-day'
                            "
                        >
                            {{ $t(`${day.name}`).substring(0, 1) }}
                        </span>
                        <span class="no-mobile">
                            {{ $t(`${day.name}`) }}
                        </span>
                        <i class="fas fa-copy" @click="copyDay(day.value)" />
                    </div>
                </div>
                <div class="calendar-days">
                    <div
                        v-for="day in getDays"
                        :key="`calendar-day_${day.value}`"
                        class="column-days"
                        :id="`day-${day.value}`"
                    >
                        <div
                            :id="`${day.value}-${index_hour}`"
                            v-for="(hour, index_hour) in hours"
                            :key="`half_hour_${index_hour}`"
                            :data-value="hour.time"
                            class="half-hour"
                            :class="
                                countPeriods(day.value) === maxTimeRange &&
                                'disabled'
                            "
                        >
                            <span class="hour-hover">
                                {{ formatHourCell(hour.time) }}
                            </span>
                            <div
                                :id="`${day.value}-${index_hour}-${index_timer}`"
                                v-for="(timer, index_timer) in periods"
                                :key="`time_range_${index_timer}`"
                                class="time-range"
                            >
                                <div
                                    v-if="
                                        displayCalendar &&
                                        checkSelectedCell(
                                            timer,
                                            day,
                                            index_hour
                                        )
                                    "
                                    class="selected-cell"
                                    :style="{
                                        height: `${getHeightTimer(
                                            day.value,
                                            hour.time,
                                            timer.start,
                                            timer.end
                                        )}px`,
                                        width: `${getStartPosition().width}px`,
                                    }"
                                >
                                    <div
                                        class="hour-details"
                                        :style="{
                                            left: `${
                                                getStartPosition().width + 1
                                            }px`,
                                        }"
                                    >
                                        <div>
                                            {{
                                                timer.accurateStart
                                                    ? timer.accurateStart
                                                    : formatHourCell(hour.time)
                                            }}
                                        </div>
                                        <div>-</div>
                                        <div>
                                            {{
                                                timer.accurateEnd
                                                    ? timer.accurateEnd
                                                    : formatHourCell(
                                                          hours[
                                                              parseInt(
                                                                  timer.end
                                                              ) + 1
                                                          ]?.time || "00:00:00"
                                                      )
                                            }}
                                        </div>
                                    </div>
                                    <div class="overlay">
                                        <div
                                            v-if="
                                                !readonly &&
                                                getHeightTimer(
                                                    day.value,
                                                    hour.time,
                                                    timer.start,
                                                    timer.end
                                                ) > 60
                                            "
                                            class="actions"
                                        ></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="only-mobile">
            <div class="btn-add">+</div>
        </div>
    </div>
</template>

<script>
import { useToast } from "vue-toastification"
const toast = useToast()

export default {
    name: "WeekCalendar",
    props: {
        readonly: {
            type: Boolean,
            required: false,
            default: false,
        },
        days: {
            type: Array,
            required: true,
        },
        maxTimeRange: {
            type: Number,
            required: true,
        },
        maxDifferentDay: {
            type: Number,
            required: true,
        },
        initialValue: {
            type: Array,
            required: false,
        },
        create: {
            type: Boolean,
            required: false,
            default: true,
        },
    },
    data() {
        return {
            selectedDay: null,
            selectedHour: null,
            periods: [],
            displayCalendar: true,
            openIndex: null,
            displayDays: [],
            differentDay: [],
        }
    },
    computed: {
        // Retoune toutes les heures disponible par tranche ed 30 minutes
        hours() {
            const start = 0
            const end = 23
            const hours = []

            for (let i = start; i <= end; i++) {
                if (i < 10) {
                    hours.push({ name: `0${i}h`, time: `0${i}:00:00` })
                    hours.push({ name: "", time: `0${i}:30:00` })
                } else {
                    hours.push({ name: `${i}h`, time: `0${i}:00:00` })
                    hours.push({ name: "", time: `0${i}:30:00` })
                }
            }
            return hours
        },
        // Retourne les jours à afficher
        getDays() {
            if (this.displayDays.length > 0) {
                return this.days.filter(
                    (day) =>
                        this.displayDays.findIndex((e) => e === day.value) >= 0
                )
            } else {
                return this.days
            }
        },
        // Bloque la capacité de créer de nouvelles périodes si le nombre de jour différent max est atteint
        getForceCopy() {
            return this.maxDifferentDay <= this.differentDay.length
        },
        // Formate le retour pour une utilisation par d'autres api
        formatReturn() {
            let data = []
            this.days.forEach((e) => {
                data.push({
                    weekday: e.id,
                    intervals: [],
                })
            })
            this.periods.forEach((period) => {
                const index = data.findIndex((e) => e.weekday === period.day)
                data[index]["intervals"].push({
                    start: period.accurateStart
                        ? `${period.accurateStart}:00`
                        : this.hours[parseInt(period.start)]?.time.slice(-8),
                    end: period.accurateEnd
                        ? `${period.accurateEnd}:00`
                        : this.hours[parseInt(period.end) + 1]?.time.slice(
                              -8
                          ) || "24:00:00",
                })
            })
            return data
        },
    },
    mounted() {
        this.initialValue.forEach((day) => {
            let values = []
            values[day.weekday] = true
            day.timeslot.forEach((time) => {
                this.createTimeRange(
                    time.start.substring(0, 5),
                    time.end.substring(0, 5),
                    values
                )
            })
        })
    },
    watch: {
        periods() {
            this.getDifferentDay()
        },
    },
    created() {
        // Listener lors du resize de la fenêtre
        window.addEventListener("resize", this.resizeWindow)
    },
    unmounted() {
        // destruction du listener
        window.removeEventListener("resize", this.resizeWindow)
    },
    methods: {
        getDifferentDay() {
            this.differentDay = []
            const filterPeriods = []
            this.days.forEach((value, index) => {
                filterPeriods[index] = []
                this.periods.forEach((e) => {
                    if (e.day === index) {
                        filterPeriods[index].push({
                            start: e.start,
                            end: e.end,
                            accurateStart: e.accurateStart,
                            accurateEnd: e.accurateEnd,
                        })
                    }
                })
            })

            filterPeriods.forEach((periods) => {
                if (periods.length > 0) {
                    let find = false
                    this.differentDay.forEach((diff) => {
                        if (this.deepEqual(periods, diff)) {
                            find = true
                        }
                    })
                    if (!find) {
                        this.differentDay.push(periods)
                    }
                }
            })

            this.$emit("change", {
                periods: this.formatReturn,
            })
        },
        deepEqual(x, y) {
            const ok = Object.keys,
                tx = typeof x,
                ty = typeof y
            return x && y && tx === "object" && tx === ty
                ? ok(x).length === ok(y).length &&
                      ok(x).every((key) => this.deepEqual(x[key], y[key]))
                : x === y
        },
        countPeriods(day) {
            let count = 0
            this.periods.forEach((period) => {
                if (period.day === day) {
                    count++
                }
            })
            return count
        },
        formatHour(time, forceMobile = false) {
            if (forceMobile) return time.toUpperCase()
            else return `${time.toUpperCase()}00`
        },
        formatHourCell(hour) {
            let retour = ""
            let test = hour.split(":")
            if (parseInt(test[0]) > 9) {
                retour = parseInt(test[0])
            } else {
                retour = `0${parseInt(test[0])}`
            }
            if (parseInt(test[1]) > 9) {
                retour += `:${parseInt(test[1])}`
            } else {
                retour += `:0${parseInt(test[1])}`
            }
            return retour
        },
        startSelectCell(e) {
            if (this.getForceCopy && e.target.id) {
                this.openCopyModal()
            } else {
                if (e.target.id) {
                    const element = document.getElementById(e.target.id)
                    if (element) element.classList.add("drag")
                    const selectedId = e.target.id.split("-")
                    this.selectedDay = selectedId[0]
                    this.selectedHour = selectedId[1]
                }
            }
        },
        stopSelectCell(e) {
            if (e.target.id) {
                const selectedId = e.target.id.split("-")
                const endDay = selectedId[0]
                const endHour = selectedId[1]

                if (this.selectedDay === endDay) {
                    this.periods.push({
                        day: parseInt(endDay),
                        start: this.selectedHour,
                        end: endHour,
                    })
                }
                this.fusionTimeRange()
                this.selectedDay = null
                this.selectedHour = null
                this.clearDrag()
            }
            window.getSelection()?.removeAllRanges()
        },
        selectCell(e) {
            if (this.selectedDay && this.selectedHour) {
                const element = document.getElementById(e.target.id)
                if (element) element.classList.add("drag")
            }
        },
        clearDrag() {
            let htmlCollection = null
            do {
                htmlCollection = document.getElementsByClassName("drag")
                for (let item of htmlCollection) {
                    item.classList.remove("drag")
                }
            } while (htmlCollection.length > 0)
        },
        setIfMatchHourCell(cell, start) {
            return cell === start
        },
        checkSelectedCell(timer, day, index_hour) {
            return (
                timer.day === day.value &&
                this.setIfMatchHourCell(
                    index_hour,
                    parseInt(timer.start),
                    parseInt(timer.end)
                )
            )
        },
        getStartPosition() {
            let element = document.getElementById(this.getIdFirstElement())
            let width = 0
            let height = 0

            if (element) {
                width = element.offsetWidth
                height = element.offsetHeight
            }
            return {
                width,
                height,
            }
        },
        getHeightTimer(day, cellHour, timerStartIndex, timerEndIndex) {
            let timerEnd = ""
            const timerStart = this.hours[timerStartIndex].time
            if (timerEndIndex === -2) {
                timerEnd = this.hours[this.hours.length - 1].time
            } else {
                timerEnd = this.hours[timerEndIndex].time
            }
            const dayColumn = document.getElementById("day-" + day)
            const minStart = parseInt(timerStart.split(":")[1])
            const minEnd = parseInt(timerEnd.split(":")[1])
            const startPosition = this.getStartPosition()
            let height = startPosition.height

            if (
                !(cellHour === "00:00:00" && timerEnd === "00:00:00") &&
                dayColumn
            ) {
                if (timerEnd === "00:00:00") timerEnd = "23:30:00"

                if (
                    timerEnd.split(":")[1] !== "00" &&
                    timerEnd.split(":")[1] !== "30"
                )
                    timerEnd = timerEnd.split(":")[0] + ":" + "30:00"

                const startCell = dayColumn.querySelector(
                    '.half-hour[data-value="' + cellHour + '"]'
                )
                const endCell = dayColumn.querySelector(
                    '.half-hour[data-value="' + timerEnd + '"]'
                )

                let numberOfHalfHour =
                    parseInt(endCell.id.split("-")[1]) -
                    parseInt(startCell.id.split("-")[1])
                height += numberOfHalfHour * startPosition.height

                if (
                    (((minStart > 7 && minStart < 23) ||
                        (minStart > 37 && minStart < 53)) &&
                        minEnd < 38 &&
                        minEnd > 22) ||
                    (minStart < 38 &&
                        minStart > 22 &&
                        minEnd < 23 &&
                        minEnd > 7) ||
                    (minStart < 8 &&
                        minStart > 0 &&
                        minEnd < 23 &&
                        minEnd > 7) ||
                    (minStart > 37 && minStart < 53 && minEnd == 0)
                )
                    height -= 4
                if (
                    (((minStart < 23 && minStart > 7) ||
                        (minStart > 37 && minStart < 53)) &&
                        minStart !== 30 &&
                        minEnd > 7 &&
                        minEnd < 23) ||
                    (minStart < 38 && minEnd > 22 && minEnd < 8) ||
                    (minStart == 30 && minEnd > 0 && minEnd < 8)
                )
                    height -= 14
                if (
                    ((minStart < 23 && minStart > 7) ||
                        (minStart > 37 && minStart < 53)) &&
                    minEnd > 0 &&
                    minEnd < 8
                )
                    height -= 29
                if (
                    (((minStart < 23 && minStart > 7) ||
                        (minStart > 37 && minStart < 53)) &&
                        minEnd > 51) ||
                    (minStart < 8 && minEnd > 37 && minEnd < 52) ||
                    (minStart < 38 &&
                        minStart > 22 &&
                        minEnd > 37 &&
                        minEnd < 53) ||
                    (minStart > 0 && minStart < 8 && minEnd > 37 && minEnd < 53)
                )
                    height += 8
                if (
                    ((minStart >= 0 && minStart < 8) ||
                        (minStart > 22 && minStart < 38)) &&
                    minEnd > 52 &&
                    minEnd <= 59
                )
                    height += startPosition.height
                if (minStart >= 8 && minStart < 23 && minEnd === 0) height -= 4
                if (minStart === 52 && minEnd === 0) height -= 6
                if (
                    (minStart >= 53 && minEnd === 0) ||
                    (minStart < 7 && minEnd > 0 && minEnd < 8)
                )
                    height -= startPosition.height
                if (timerEnd === "00:00:00") height += startPosition.height
            }
            return height
        },
        async resizeWindow() {
            this.displayCalendar = false
            await this.$nextTick
            this.displayCalendar = true
        },
        openModalEdit(
            day,
            index_timer,
            timer,
            isCallable,
            displayDelete = false
        ) {
            if (isCallable) {
                let start = this.hours[parseInt(timer.start)].time
                let end =
                    this.hours[parseInt(timer.end) + 1]?.time || "00:00:00"
                if (timer.accurateStart) {
                    start = timer.accurateStart
                }
                if (timer.accurateEnd) {
                    end = timer.accurateEnd
                }

                this.openIndex = index_timer
                this.openModal("edit-time-range", {
                    title: "edit-time-range",
                    days: this.days,
                    day,
                    start,
                    end,
                    displayDelete,
                    deleteTimeRange: () => {
                        this.deleteTimeRange()
                    },
                    editTimeRange: (payload) => {
                        this.editTimeRange(payload.start, payload.end)
                    },
                })
            }
        },
        deleteTimeRange(index = this.openIndex) {
            this.periods.splice(index, 1)
            this.getDifferentDay()
            this.closeModal()
        },
        searchValues(start, end) {
            let startSearch = ""
            let endSearch = ""
            let accurateStart = null
            let accurateEnd = null

            if (parseInt(start.split(":")[0]) > 9) {
                startSearch = `0${start.split(":")[0]}:`
            } else {
                startSearch = `${start.split(":")[0]}:`
            }
            if (
                parseInt(start.split(":")[1]) === 0 ||
                parseInt(start.split(":")[1]) === 30
            ) {
                startSearch += `${start.split(":")[1]}:00`
            } else if (parseInt(start.split(":")[1]) < 30) {
                accurateStart = `${start}`
                startSearch += `00:00`
            } else if (parseInt(start.split(":")[1]) > 30) {
                accurateStart = `${start}`
                startSearch += `30:00`
            }

            if (parseInt(end.split(":")[0]) > 9) {
                endSearch = `0${end.split(":")[0]}:`
            } else {
                endSearch = `${end.split(":")[0]}:`
            }
            if (
                parseInt(end.split(":")[1]) === 0 ||
                parseInt(end.split(":")[1]) === 30
            ) {
                endSearch += `${end.split(":")[1]}:00`
            } else if (parseInt(end.split(":")[1]) < 30) {
                accurateEnd = `${end}`
                endSearch += `00:00`
            } else if (parseInt(end.split(":")[1]) > 30) {
                accurateEnd = `${end}`
                endSearch += `30:00`
            }
            const indexStart = this.hours.findIndex(
                (e) => startSearch === e.time
            )
            const indexEnd = this.hours.findIndex((e) => endSearch === e.time)
            return {
                accurateStart,
                accurateEnd,
                start: indexStart,
                end: indexEnd,
            }
        },
        editTimeRange(start, end) {
            this.closeModal()
            const getSearch = this.searchValues(start, end)
            const indexStart = getSearch.start
            const indexEnd = getSearch.end

            const oldValue = this.periods[this.openIndex]

            if (indexStart > 0 && indexEnd > 0) {
                this.periods[this.openIndex] = {
                    day: oldValue.day,
                    start: indexStart.toString(),
                    end: (indexEnd - 1).toString(),
                }
                this.fusionTimeRange()
            } else {
                let approximateStart = indexStart
                let approximateEnd = indexEnd - 1
                let accurateStart = null
                let accurateEnd = null

                if (indexStart < 0) {
                    approximateStart = this.searchApproximateValues(start)
                    accurateStart = start
                }
                if (indexEnd < 0) {
                    approximateEnd = this.searchApproximateValues(end, true)
                    accurateEnd = end
                }

                this.periods[this.openIndex] = {
                    day: oldValue.day,
                    start: approximateStart.toString(),
                    end: approximateEnd.toString(),
                    accurateStart,
                    accurateEnd,
                }
                this.getDifferentDay()
            }
        },
        searchApproximateValues(value, isEnd = false) {
            let approximate = null
            const splitValue = value.split(":")
            this.hours.forEach((hour, index) => {
                if (
                    hour.time.split(":")[0].substr(-2) === splitValue[0] &&
                    hour.time.split(":")[1] === "30"
                ) {
                    if (parseInt(splitValue[1]) > 30) {
                        approximate = index
                    } else {
                        approximate = index - 1
                        if (isEnd) approximate -= 1
                    }
                }
            })
            return approximate
        },
        fusionTimeRange() {
            const filterPeriods = []
            this.days.forEach((value, index) => {
                filterPeriods[index] = []
                this.periods.forEach((e) => {
                    if (e.day === index) {
                        filterPeriods[index].push({
                            start: e.start,
                            end: e.end,
                            accurateStart: e.accurateStart,
                            accurateEnd: e.accurateEnd,
                        })
                    }
                })
            })

            filterPeriods.forEach(() => {
                filterPeriods.forEach((periods, day) => {
                    periods.forEach((detail, index) => {
                        const indexDelete = periods.findIndex(
                            (e) =>
                                parseInt(detail.start) < parseInt(e.start) &&
                                parseInt(detail.end) > parseInt(e.end)
                        )
                        const indexFusion = periods.findIndex(
                            (e) =>
                                parseInt(detail.start) < parseInt(e.start) &&
                                parseInt(detail.end) > parseInt(e.start) - 1 &&
                                parseInt(detail.end) < parseInt(e.end)
                        )
                        const indexCombine = periods.findIndex(
                            (e) =>
                                parseInt(detail.end) === parseInt(e.start) - 1
                        )

                        if (indexDelete >= 0) {
                            filterPeriods[day].splice(indexDelete, 1)
                        } else if (indexFusion >= 0) {
                            filterPeriods[day][index] = {
                                start: detail.start,
                                end: periods[indexFusion].end,
                                accurateStart: detail.accurateStart,
                                accurateEnd: detail.accurateEnd,
                            }
                            filterPeriods[day].splice(indexFusion, 1)
                        } else if (indexCombine >= 0) {
                            filterPeriods[day][index] = {
                                start: detail.start,
                                end: periods[indexCombine].end,
                                accurateStart: detail.accurateStart,
                                accurateEnd: detail.accurateEnd,
                            }
                            filterPeriods[day].splice(indexCombine, 1)
                        }
                    })
                })
            })

            this.periods = []
            filterPeriods.forEach((values, index) => {
                values.forEach((e) => {
                    this.periods.push({
                        day: index,
                        start: e.start,
                        end: e.end,
                        accurateStart: e.accurateStart,
                        accurateEnd: e.accurateEnd,
                    })
                })
            })
        },
        async displayDay(id) {
            const index = this.displayDays.findIndex((e) => e === id)
            if (index >= 0) {
                this.displayDays.splice(index, 1)
            } else {
                this.displayDays.push(id)
            }
            await this.resizeWindow()
        },
        getIdFirstElement() {
            let start = 0
            if (this.displayDays.length > 0) {
                start = this.displayDays.sort()[0]
            }
            return `${start}-0`
        },
        modalCreate() {
            if (this.getForceCopy) {
                this.openCopyModal()
            } else {
                this.openModal("edit-time-range", {
                    title: "create-time-range",
                    displayCreate: true,
                    days: this.days,
                    createTimeRange: (payload) => {
                        this.createTimeRange(
                            payload.start,
                            payload.end,
                            payload.selectedDays
                        )
                    },
                })
            }
        },
        createTimeRange(start, end, days) {
            this.closeModal()
            const getSearch = this.searchValues(start, end)
            days.forEach((e, index) => {
                this.periods.push({
                    accurateStart: getSearch.accurateStart,
                    accurateEnd: getSearch.accurateEnd,
                    start: getSearch.start,
                    end: getSearch.end - 1,
                    day: index,
                })
            })
            this.fusionTimeRange()
        },
        copyDay(dayId) {
            this.openModal("copie-day-time-range", {
                title: "copie-day-time-range",
                days: this.days,
                selectedDay: dayId,
                saveCopy: (payload) => {
                    this.copySelectedDays(payload.days, payload.originDay)
                },
            })
        },
        copySelectedDays(days, origin) {
            this.closeModal()
            const tempPeriod = this.periods
            const indexDelete = []
            tempPeriod.forEach((period, index) => {
                if (days[period.day]) {
                    indexDelete.push(index)
                }
            })
            indexDelete.sort(function (a, b) {
                return a - b
            })
            indexDelete.reverse()
            indexDelete.forEach((index) => {
                this.periods.splice(index, 1)
            })

            const periodsToAdd = []
            tempPeriod.forEach((period) => {
                if (period.day === origin) {
                    periodsToAdd.push(period)
                }
            })
            days.forEach((day, index) => {
                periodsToAdd.forEach((period) => {
                    this.periods.push({
                        day: index,
                        start: period.start,
                        end: period.end,
                        accurateStart: period.accurateStart,
                        accurateEnd: period.accurateEnd,
                    })
                })
            })
            this.getDifferentDay()
        },
        openCopyModal() {
            this.openModal("copie-day-time-range", {
                title: "copie-day-time-range",
                days: this.days,
                saveCopy: (payload) => {
                    this.copySelectedDays(payload.days, payload.originDay)
                },
            })
        },
    },
}
</script>

<style scoped lang="scss">
@import "@/assets/scss/_loader.scss";
@import "@/assets/scss/variables/fontSize.scss";
.week-calendar {
    margin-top: -75px;
    margin-bottom: 20px;
    @media all and (max-width: 768px) {
        .only-mobile {
            display: flex;
            .btn-add {
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                align-content: center;
                position: absolute;
                top: 92%;
                left: 85%;
                background-color: $orange-neutral;
                width: 40px;
                height: 40px;
                border-radius: 30px;
                font-size: $veryBig;
                color: $white;
                cursor: pointer;
                z-index: 10;
                &.disabled {
                    background-color: $grey;
                    cursor: not-allowed;
                }
            }
        }
        .no-mobile {
            display: none;
        }
    }
    .btn-style {
        padding: 20px;
    }
    @media all and (min-width: 768px) {
        .only-mobile {
            display: none;
        }
        .no-mobile {
            max-width: 97%;
            display: flex;
            flex-direction: row;
            justify-content: flex-end;
            align-items: center;
            align-content: center;
            .btn-add {
                position: relative;
                left: -120px;
                //border-radius: 30px;
                background-color: $orange-neutral;
                font-size: $normal;
                color: $white;
                cursor: pointer;
                padding: 10px;
                &.disabled {
                    background-color: $grey;
                    cursor: not-allowed;
                }
            }
        }
    }

    .calendar-week {
        display: flex;
        flex-direction: row;
        font-family: $font_avenir-black;
        color: $blue-lighter;
        font-size: $normal;
        width: 100%;
        height: 100%;
        @media all and (max-width: 768px) {
            height: 95%;
        }
        .hours {
            margin-top: 35px;
            width: 10%;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-end;
            align-content: flex-start;
            height: max-content;
            .hour {
                margin-right: 3px;
                height: 15px;
                padding: 2px;
                @media all and (max-width: 768px) {
                    padding: 2.2px;
                }
            }
            &.padding-border {
                padding-top: 5px;
            }
        }
        .calendar-cells {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            align-content: flex-start;
            width: 100%;
            height: 100%;
            cursor: crosshair;
            &.readonly {
                cursor: not-allowed;
            }
            .days {
                width: 90%;
                display: flex;
                flex-direction: row;
                justify-content: space-around;
                align-items: center;
                align-content: center;
                padding-top: 10px;
                padding-bottom: 10px;
                .day {
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    align-items: center;
                    align-content: center;
                    width: 100%;
                    height: 100%;
                    i {
                        display: flex;
                        flex-direction: row;
                        justify-content: center;
                        align-items: center;
                        align-content: center;
                        padding-left: 5px;
                        cursor: pointer;
                        @media all and (max-width: 768px) {
                            font-size: $small;
                        }
                    }
                    & .only-mobile {
                        cursor: pointer;
                    }
                    & .selected-day {
                        display: flex;
                        flex-direction: row;
                        justify-content: center;
                        align-items: center;
                        align-content: center;
                        background-color: $orange-neutral;
                        height: 25px;
                        width: 25px;
                        border-radius: 20px;
                        color: $white;
                    }
                }
            }
            .calendar-days {
                width: 90%;
                display: flex;
                flex-direction: row;
                justify-content: space-around;
                align-items: center;
                align-content: center;
                background-color: rgba(255, 255, 255, 0.1);
                z-index: 1;
                .column-days {
                    width: 100%;
                    border-left: solid 0.5px $light-grey;
                    &:last-child {
                        border-right: solid 0.5px $light-grey;
                    }
                    .half-hour {
                        border-top: solid 0.5px $light-grey;
                        height: 15px;
                        .hour-hover {
                            display: none;
                            z-index: 0;
                        }
                        &:last-child {
                            border-bottom: solid 0.5px $light-grey;
                        }
                        &.drag {
                            background-color: $grey-lighter;
                        }
                        &.disabled {
                            background-color: $grey-lighter;
                            cursor: not-allowed;
                        }
                        .time-range {
                            .selected-cell {
                                display: flex;
                                position: relative;
                                right: 0;
                                &:hover > .hour-details {
                                    display: flex;
                                }
                                .hour-details {
                                    display: none;
                                    flex-direction: row;
                                    justify-content: center;
                                    align-items: center;
                                    align-content: center;
                                    flex-wrap: wrap;
                                    gap: 5px;
                                    position: absolute;
                                    z-index: 1;
                                    padding: 5px;
                                    width: 100%;
                                    background-color: $white;
                                    border: solid 1px $blue-white;
                                    border-radius: 20px;
                                    font-size: $small;
                                }
                                .overlay {
                                    background-color: $blue-white;
                                    border-radius: 20px;
                                    width: 100%;
                                    height: 100%;
                                    font-size: $normal;
                                    color: $blue-white;
                                    opacity: 0.8;
                                    cursor: pointer;
                                    .actions {
                                        display: flex;
                                        flex-direction: row;
                                        justify-content: space-around;
                                        align-items: flex-start;
                                        align-content: flex-start;
                                        flex-wrap: wrap;
                                        gap: 5px;
                                        width: 100%;
                                        padding-top: 5px;
                                        i {
                                            display: flex;
                                            flex-direction: row;
                                            justify-content: center;
                                            align-items: center;
                                            align-content: center;
                                            width: 30px;
                                            height: 30px;
                                            border-radius: 20px;
                                            background-color: $white;
                                            box-shadow: 1px 1px 3px 1px
                                                $orange-neutral;
                                            &:hover {
                                                background-color: $orange-neutral;
                                                color: $white;
                                                box-shadow: unset;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                        &:hover {
                            background-color: $grey-lighter;
                            > .hour-hover {
                                display: flex;
                                position: relative;
                                margin-top: -30px;
                            }
                        }
                    }
                }
            }
        }
    }
}
</style>
